<template>
  <div class="workForm">
    <div class="item">
      <div class="item-l">租用房号:</div>
      <div class="item-r">
        <input type="text" v-model="postData.name" disabled />
        <!-- <v-input disabled v-model="postData.name"></v-input> -->
      </div>
    </div>
    <div class="item">
      <div class="item-l">姓名:</div>
      <div class="item-r">
        <input
          type="text"
          v-model="form.userName"
          :maxlength="10"
          placeholder="请填写姓名"
        />
        <!-- <v-input
          v-model="form.userName"
          :maxlength="10"
          placeholder="请填写姓名"
        ></v-input> -->
      </div>
    </div>
    <div class="item">
      <div class="item-l">手机号:</div>
      <div class="item-r">
        <input
          type="text"
          v-model="form.mobile"
          :maxlength="20"
          placeholder="请填写手机号"
        />
        <!-- <v-input
          v-model="form.mobile"
          :maxlength="20"
          placeholder="请填写手机号"
        ></v-input> -->
      </div>
    </div>
    <div class="item">
      <div class="item-l">公司:</div>
      <div class="item-r">
        <input
          type="text"
          v-model="form.companyName"
          :maxlength="50"
          placeholder="请填写公司名称"
        />
        <!-- <v-input
          v-model="form.companyName"
          :maxlength="50"
          placeholder="请填写公司名称"
          >></v-input
        > -->
      </div>
    </div>
    <div class="item te">
      <div class="item-l">公司简介:</div>
      <div class="item-r">
        <!-- <v-input
          v-model="form.companyProfile"
          placeholder="请填写公司简介"
          type="textarea"
          :maxlength="150"
          :showWordLimit="true"
        ></v-input> -->
      </div>
    </div>
    <div class="message">
      <van-field
        style="background: transparent; height: 100%"
        v-model="form.companyProfile"
        type="textarea"
        maxlength="150"
        autosize
        row="5"
        placeholder="请填写公司简介～"
        show-word-limit
      />
    </div>

    <div class="workForm-b" @click="submit">
      <div class="btn-r">租赁申请</div>
    </div>
  </div>
</template>

<script>
import { postRentOrderUrl, createWorkDetail } from "./api.js";

export default {
  name: "workForm",
  data() {
    return {
      disabled: false,
      form: {
        userName: "",
        mobile: "",
        companyName: "",
        companyProfile: "",
      },
      spaceId: "",
      postData: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  created() {
    this.spaceId = this.$route.query.spaceId;
    this.postData.name = this.$route.query.name;
  },
  mounted() {},
  methods: {
    toMyApply() {
      this.$router.push({
        name: "rentSuccess",
      });
    },
    submit() {
      if (!this.form.userName) {
        this.$toast({ message: "请填写姓名", duration: 2000 });
        return;
      }
      if (!this.form.mobile) {
        this.$toast({ message: "请填写手机号", duration: 2000 });
        return;
      }
      if (!this.form.companyName) {
        this.$toast({ message: "请填写公司名称", duration: 2000 });
        return;
      }
      if (!this.form.companyProfile) {
        this.$toast({ message: "请填写公司简介", duration: 2000 });
        return;
      }
      let params = {
        spaceId: this.spaceId,
        spaceName: this.postData.name,
        userName: this.form.userName,
        mobile: this.form.mobile,
        companyName: this.form.companyName,
        companyProfile: this.form.companyProfile,
        openId: this.openId,
        tenantId: this.tenantId,
        source: 1,
      };
      this.$axios
        .post(`${postRentOrderUrl}`, params, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast({ message: "提交申请成功", duration: 2000 });
            this.$router.push({
              name: "rentSuccess",
            });
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.workForm {
  min-height: 100vh;
  padding: 40px;
  box-sizing: border-box;
  overflow: hidden;
  .message {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 16px;
  }
  .item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    height: 106px;
    line-height: 106px;
    overflow: hidden;
    .item-l {
      font-size: 32px;
      color: #333333;
      width: 160px;
    }
    .item-r {
      flex: 1;
      input {
        background-color: transparent !important;
        width: 100%;
        font-size: 28px;
        color: rgba(0, 0, 0, 0.5);
        text-align: right;
      }
    }
  }
  .te {
    // align-items: flex-start;
    border: none;
    // .item-l {
    //   padding-top: 20px;
    // }
  }
  .submit {
    width: 686px;
    height: 80px;
    background: #009aff;
    border-radius: 8px;
    font-size: 32px;
    line-height: 80px;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 100px;
    margin: auto;
    color: #fff;
    display: flex;
    align-items: center;
    .block {
      width: 1px;
      height: 60%;
      background: #fff;
    }
    .submit-l,
    .submit-r {
      flex: 1;
    }
  }
  .workForm-b {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #9591ff 0%, #5f5bff 100%);
    border-radius: 10px;
    line-height: 66px;
    position: fixed;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    // padding-bottom: constant(safe-area-inset-bottom);
    // padding-bottom: env(safe-area-inset-bottom);
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    // .btn-l {
    //   width: 60px;
    //   height: 60px;
    //   margin-right: 20px;
    //   img {
    //     width: 100%;
    //     height: 100%;
    //   }
    // }
    // .btn-r {
    //   color: #ffff;
    //   font-size: 32px;
    //   line-height: 96px;
    // }
  }
  .disabled {
    background: #999;
    .block {
      width: 1px;
      height: 60%;
      background: #888;
    }
  }
}
</style>
